<template>
    <v-dialog :value="value" persistent width="500px">
    <v-card color='#fafafa' width='500px'>
      <v-toolbar flat dark class="main">
        <v-toolbar-title>
        Billing Summaries
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 px-4" fluid>
      <v-col>
      <v-menu
        v-model="run_dt_picker"
        transition="scale-transition"
        offset-y
        nudge-top="25"
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="run_date"
                    label="Run Date"
                    ref="run_date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="#fff"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="[isValidRunDate]">
                </v-text-field>
        </template>
            <v-date-picker 
                  v-model="run_date"
                  :max="today"
                  no-title>
            </v-date-picker>
        </v-menu>
        </v-col>
        <v-card-text class="mt-n8">
          <span><strong>Summary Select:</strong></span>
          <v-row class="mt-1">
            <template v-for="summary in currentSummaries">
              <v-checkbox
                :key="summary.key"
                dense 
                class='ml-2' 
                v-model="summary.value"
                :label="summary.label">
              </v-checkbox>
            </template>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-n3">
          <span><strong>Warning:</strong></span>
          <v-checkbox
            dense 
            v-model="agreed"
            :label="warningText">
          </v-checkbox>
        </v-card-text>
      </v-container>
      <v-footer color=#fafafa>
        <v-spacer/>
        <v-btn
          color="gray" 
          class='mb-3 mr-5' 
          dense 
          medium
          @click="$emit('closeProcessModal')">
          Cancel
        </v-btn>
        <v-btn 
          color="success" 
          class='mb-3 mr-5' 
          dense 
          medium 
          :disabled="startButtonDisabled" 
          :loading="loading"
          @click="triggerBillingSummaries()">
          Start
        </v-btn>
      </v-footer>
    </v-card> 
  </v-dialog>
</template>
<script>
import { displayAlert } from '@/mixins/displayAlert'
import { utils } from '@/mixins/utils'
export default {
  name: 'BillingSummaries',
  props: ['value'],
  mixins: [displayAlert, utils],
  data () {
    return {
      run_date: new Date().toISOString().slice(0, 10),
      today: new Date().toISOString().slice(0, 10),
      run_dt_picker: false,
      agreed: false,
      loading: false,
      valid: true,
      warningText: "I understand that running billing summaries before all statements for a given day have finished will result in missing information.",
      summaries: [
        { label: 'Accounting Totals', key: 'accounting', value: false, show: (this.$auth.tenant === 'awg') },
        { label: 'Lump Sum Summary', key: 'lumps', value: false, show: true },
        { label: 'Vendor Accounting Totals', key: 'vendor_acct', value: false, show: (this.$auth.tenant === 'awg') }
      ]
    }
  },
  computed: {
    currentSummaries () {
      return this.summaries.filter(summary => summary.show)
    },
    startButtonDisabled () {
      return (!this.agreed || this.loading || !this.valid)
        || this.summaries.every(summary => !summary.value)
    },
    successText () {
      const labels = this.summaries.flatMap(summary => summary.value ? summary.label : [])
      const summaryText = (labels.length === 3) ?
        `${labels[0]}, ${labels[1]}, and ${labels[2]}`
        : labels.join(' and ')
      return `Successfully started ${summaryText}`
    }
  },
  methods: {
    async triggerBillingSummaries() {
      this.loading = true
      try {
        const params = this.summaries.reduce((obj, summary) => {
          obj[summary.key] = summary.value
          return obj
        }, {})
        params.run_date = this.run_date
        const res = await this.$BillingBatch.billingSummariesTrigger(params)
        if (res?.status === 200) {
          this.emitAlert(true, 'success', this.successText)
        }
        this.$emit('closeProcessModal')
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },

    isValidRunDate (value) {
      if (value) {
        if (!this.isValidYear(value)) {
          this.run_dt_picker = false
          this.valid = false
          return 'Invalid year value'
        }
        this.valid = true
        return true
      }
      this.valid = false
      return 'Start Date is required'
  },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        if (year == currentYear) {
          return true
        }
        else {
          return false
        }
      }
      return false
    },
}
}
</script>
<style>
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}
</style>
